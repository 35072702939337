import { React, Page } from 'lib'

import AboutPage from './_about'

function About(props) {
  return (
    <Page>
      <AboutPage/>
    </Page>
  )
}

export default About
